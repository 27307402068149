import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import ContentHeader from "../components/layout/content-header"
import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import SEO from "../components/seo/seo"
import RichTextField from "../components/textfield/rich"

class FestivalArticleTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()
    }

    animate(){
      this.pageTransition.current.play()
    }

    render(){

        const { article } = this.props.data
        const locale = this.props.pageContext.locale
        return (
          <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
          <SEO
            title={article.seo.title}
            description={article.seo.description}
            keywords={article.seo.keywords && article.seo.keywords.keywords}
            image={article.seo.image.file.url}
          />
            <FestivalNavigation locale={locale} links={article.navigation.links} color={'#F5867B'} />
            <div className={"container mx-auto"}>
              <div className={"w-full lg:w-10/12 lg:px-4 mx-auto "}>
                <ContentHeader title={article.title} imagePath={article.image && article.image.file.url} />
              </div>
            </div>
            <FestivalBackground wide={article.theme.widescreenBackground.file.url}>
                <div className={"container mx-auto"}>
                  <div className={"px-2 w-2/3 mx-auto"}>
                    <RichTextField json={article.content.json} locale={locale} />
                  </div>
                </div>
            </FestivalBackground>
            <TransitionPortal>
              <PageTransition ref={this.pageTransition} />
            </TransitionPortal>
          </Layout>
        )
    }
}

export const pageQuery = graphql`
query festivalArticleQuery($slug: String!) {
    article:contentfulFestivalArticle(slug: { eq: $slug }) {
      title
      slug
      short
      seo {
        title
        description
        keywords {
          keywords
        }
        image {
          file {
            url
          }
        }
      }
      category {
        slug
      }
      image {
        file {
          url
        }
      }
      content {
        json
      }
      tags
      theme {
      ... on ContentfulTheme {
        logo {
          file {
            url
          }
        }
        animation{
          file{
            url
          }
        }
        portraitBackground {
          file {
            url
          }
        }
        portraitHeaderImage {
          file {
            url
          }
        }
        widescreenBackground {
          file {
            url
          }
        }
        widescreenHeaderImage {
          file {
            url
          }
        }
        colorPrimary
        colorSecondary
      }
    }
    navigation{
      ...on ContentfulNavigation{
        links{
          ...on ContentfulLink{
            title
            href
          }
        }
      }
    }
    }
}
`

export default FestivalArticleTemplate
